import { useSelector } from "react-redux"
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useState } from "react";

const Footer = () => {



    return (
        <>

            <footer className="footer">
                <div className="footer-container">
                    <div className="footer-content">
                        <div className="footer-logo">
                            <img src="/assets/logo.png" alt="logo" />
                            <a >
                                490 Cherry Hill Drive, Madison, Mississippi 39110, United States
                            </a>
                            <p>
                                Your local, community-based exterior cleaner. Turning commercial properties into neighborhood gems. Let's make cleaning great again! Faster, safer, and more efficient.
                            </p>
                        </div>
                        <div className="footer-links">
                            <div className="footer-link-group">
                                <h4>Operating Hours</h4>
                                <ul>
                                    <li>
                                        <span>Mon - Sat: 8am - 5pm</span>
                                    </li>
                                    <li>
                                        <span>Sun: Closed</span>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-link-group">
                                <h4>Company</h4>
                                <ul>
                                    <li>
                                        <Link to="/about">About Us</Link>
                                    </li>
                                    <li>
                                        <Link to="/commercial-industrial">Services</Link>
                                    </li>
                                    <li>
                                        <Link to="/contact">Contact Us</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="footer-link-group">
                                <h4>Services</h4>
                                <ul>
                                    <li>
                                        <Link to="/commercial-industrial">Commercial & Industrial</Link>
                                    </li>
                                    <li>
                                        <Link to="/residential-home">Residential & Home</Link>
                                    </li>
                                    <li>
                                        <Link to="/why-drones">Why Drones?</Link>
                                    </li>

                                </ul>
                            </div>

                        </div>
                    </div>
                    <div className="footer-bottom">
                        <p>&copy; 2024 FlyBy Shine. All Rights Reserved.</p>
                        <ul>
                            <li>
                                <Link to="/privacy-policy">Privacy Policy</Link>
                            </li>
                            <li>
                                <Link to="/terms-of-service">Terms of Service</Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer