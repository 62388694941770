import { combineReducers, configureStore } from '@reduxjs/toolkit';
import authSlice from './schemas/authSlice';

export const createReduxStore = (preloadedState) => {
  return configureStore({
    reducer: {
      auth: authSlice,
       // mainapp: combineReducers({
      
      // })
    },
    preloadedState
  });
};

// For client-side: 
