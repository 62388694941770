import React, { useEffect, useState } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import NonLodable from './publicPages/nonLodable';
import loadable from '@loadable/component';
import Page404 from './publicPages/404';
import { useSelector } from 'react-redux';
import Footer from './publicPages/components/footer';
import { ClientOnly } from '../library/client/serverCallHandler.js';
import ReactGA from "react-ga4";


const RenderCms = loadable(() => import('../.cms/gui/renderCms.js'))
const RenderAi = loadable(() => import('./ai/renderAi.js'))
const LandingPage = loadable(() => import('./publicPages/landingPage'))
const AboutUs = loadable(() => import('./publicPages/aboutus'))
const Commercial = loadable(() => import('./publicPages/commercial/commercial'))
const Residential = loadable(() => import('./publicPages/residential/residential'))
const SoftWashPage = loadable(() => import('./publicPages/commercial/softwashPage.js'))
const Dumpster = loadable(() => import('./publicPages/commercial/dumpster.js'))
const MultiStoryPage = loadable(() => import('./publicPages/commercial/multistoryPage.js'))
const ParkingLotPage = loadable(() => import('./publicPages/commercial/parkinglotPage.js'))
const WindowPage = loadable(() => import('./publicPages/commercial/windowPage.js'))
const IndustrialPage = loadable(() => import('./publicPages/commercial/industrialPage.js'))
const OilPage = loadable(() => import('./publicPages/commercial/oilPage.js'))
const StadiumPage = loadable(() => import('./publicPages/commercial/stadiumPage.js'))
const WaterTowerPage = loadable(() => import('./publicPages/commercial/waterTowerPage.js'))
const SoftWashPage2 = loadable(() => import('./publicPages/residential/softwashPage2.js'))

const WhyDrones = loadable(() => import('./publicPages/whydrones.js'))
const ResSoftwashPage = loadable(() => import('./publicPages/residential/resSoftwashPage.js'))
const RoofCleaningPage = loadable(() => import('./publicPages/residential/roofCleaningPage.js'))
const GutterCleaningPage = loadable(() => import('./publicPages/residential/gutterPage.js'))
const MonumentPage = loadable(() => import('./publicPages/commercial/monumentPage.js'))
const ContactUs = loadable(() => import('./publicPages/contact.js'))
const PrivacyPolicy = loadable(() => import('./publicPages/privacyPolicy.js'))
const TOS = loadable(() => import('./publicPages/TOS.js'))

const App = () => {

    const [aosInit, setAosInit] = useState(false)

    useEffect(() => {
        ReactGA.initialize("G-23MCBF7RRM");
        if (!aosInit) {
            AOS.init();
            setAosInit(true)
        }
        if(process.env.NODE_COMPILE != 'split' && (!window.location.href.includes('localhost'))){
            const clarityScript = document.createElement("script");
            clarityScript.type = "text/javascript";
            clarityScript.setAttribute("data-prod-only", "true");
    
            clarityScript.text = `(function (c, l, a, r, i, t, y) {
                c[a] = c[a] || function () { (c[a].q = c[a].q || []).push(arguments) };
                t = l.createElement(r); t.async = 1; t.src = "https://www.clarity.ms/tag/" + i;
                y = l.getElementsByTagName(r)[0]; y.parentNode.insertBefore(t, y);
            })(window, document, "clarity", "script", "nw5cjxspmg");`;
    
            document.head.appendChild(clarityScript);
        }
    }, [])

    return (
        <>

            <Routes>
                <Route path='/' >
                    <Route path='/' element={<LandingPage />} />
                    <Route path='/about' element={<AboutUs />} />
                    <Route path='/commercial-industrial' element={<Commercial />} />
                    <Route path='/residential-home' element={<Residential />} />

                    <Route path='/drone-softwash-pressurewash' element={<SoftWashPage />} />
                    <Route path='/dumpster-pad-cleaning' element={<Dumpster />} />
                    <Route path='/multi-story-window-cleaning' element={<MultiStoryPage />} />
                    <Route path='/parking-lot-cleaning' element={<ParkingLotPage />} />
                    <Route path='/window-cleaning' element={<WindowPage />} />
                    <Route path='/industrial-cleaning' element={<IndustrialPage />} />
                    <Route path='/oil-terminal-tank-exterior-cleaning' element={<OilPage />} />
                    <Route path='/stadium-and-dome-cleaning' element={<StadiumPage />} />
                    <Route path='/water-tower-cleaning' element={<WaterTowerPage />} />

                    <Route path='/drone-softwash-pressurewash-residential' element={<SoftWashPage2 />} />

                    <Route path='/why-drones' element={<WhyDrones />} />
                    <Route path='/residential-softwash-pressurewash' element={<ResSoftwashPage />} />
                    <Route path='/roof-cleaning' element={<RoofCleaningPage />} />
                    <Route path='/gutter-cleaning' element={<GutterCleaningPage />} />
                    <Route path='/monument-restoration' element={<MonumentPage />} />
                    <Route path='/contact' element={<ContactUs />} />
                    <Route path='/privacy-policy' element={<PrivacyPolicy />} />
                    <Route path='/terms-of-service' element={<TOS />} />

                </Route>

                {/* CMS */}

                {process.env.NODE_COMPILE != 'production' && (
                    <Route path="/cms/*" element={<ClientOnly>{() => (<RenderCms />)}</ClientOnly>} />
                )}
                <Route path="/ai/*" element={<ClientOnly>{() => (<RenderAi />)}</ClientOnly>} />
                <Route path="*" element={<Page404 />} />
            </Routes>



        </>


    )
};

export default App;
