import { useState } from "react"

const Page404 = () => {

    const [state, setState] = useState()

    return (
        <div>404 This page does not exist</div>
    )
}

export default Page404